import { merge } from "theme-ui"
import { toTheme } from '@theme-ui/typography'
import altonTheme from "typography-theme-alton"

import "../fonts/fonts.css"

delete altonTheme["googleFonts"]

const typographyTheme = toTheme(altonTheme)

const theme = merge(typographyTheme, {
    colors: {
        text: "#333",
        muted: "#ccc",
        primary: "#fc9206",
        background: "#FFFFFF",
    },
    cards: {
        primary: {
            padding: 2,
            borderRadius: 0,
            borderColor: 'muted',
            backgroundColor: 'background'
        }
    },
    layout: {
        header: {
            maxHeight: 450,
            mb: 3,
            overflow: 'hidden',
        },
        content: {
            padding: 3,
            backgroundColor: 'background'
        },
        footer: {
            textAlign: "center",
            color: 'muted',
            fontSize: 0,
        },
        container: {
            maxWidth: 1144
        }
    }
})

export default theme
