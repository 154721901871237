import * as React from 'react'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Card, Text } from "@theme-ui/components"
import { Link } from "gatsby"

interface ProjectCardProps {
    title: string,
    href: string,
    image: IGatsbyImageData,
}

const ProjectCard: React.FC<ProjectCardProps> = ({ title, image, href }) => (
    <Link to={href}>
    <Card sx={{ textAlign: "center", mb: 3 }}>
        <GatsbyImage alt={title} image={image} />
        <Text sx={{ fontSize: 0 }}>{title}</Text>
    </Card>
    </Link>
)

export default ProjectCard
