import * as React from 'react'
import { graphql, Link, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Card, Flex, Grid, Text } from "@theme-ui/components"
import ProjectCard from "./ProjectCard"

interface ProjectsProps {

}

const Projects: React.FC<ProjectsProps> = () => {
    const data = useStaticQuery(graphql`
        query Projects {
          allMdx(filter: {fields: {source: {eq: "projects"}}}) {
            nodes {
              slug
              frontmatter {
                title
                image {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 300)
                  }
                }
              }
            }
          }
        }
    `)

    const nodes = data?.allMdx?.nodes.map(node =>
        (
            <ProjectCard
                title={node.frontmatter.title}
                href={`/projects/${node.slug}`}
                image={getImage(node.frontmatter.image)}
            />
        )
    )

    return (
        <Flex sx={{ justifyContent: "space-between", flexWrap: "wrap", alignItems: "center" }}>
            {nodes}
        </Flex>
    )
}

export default Projects
