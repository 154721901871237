import * as React from 'react'
import { Box, Button, Input, Label, Textarea } from "@theme-ui/components"

interface ContactFormProps {

}

const ContactForm: React.FC<ContactFormProps> = (props) => (
    <form action="https://getform.io/f/dc6893b3-7b3d-40f2-b7ef-171fba09c199" method="POST">
        <Label htmlFor={"name"}>Name</Label>
        <Input name="name" id="name" mb={3} required={true}/>
        <Label htmlFor={"email"}>E-Mailadresse</Label>
        <Input name="email" id="email" mb={3} required={true} type="email"/>
        <Label htmlFor="message">Nachricht</Label>
        <Textarea name="message" id="message" rows={6} mb={3} required={true} />
        <Button>Abschicken</Button>
    </form>
)

export default ContactForm
